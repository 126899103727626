import * as React from "react"
import { Helmet } from "react-helmet"

const ImprintPage = () => {
  return (
    <main>
      <Helmet>
        <title>Imprint</title>
      </Helmet>
      Imprint information
    </main>
  )
}

export default ImprintPage
